<template>
    <div class="wrap">
        <div class="header clearfix">
            <img :src="ruleImg" alt="" class="rule fl" @click="showRule = true">
            <img :src="closeImg" alt="" class="closeGame fr" @click="close">
            <img :src="areaImg" alt="" class="secretArea">
            <img :src="goImg" alt="" class="changeModal" @click="changeModal">
            <img :src="kunImg" alt="" class="kun" v-if="isModal === 'secretarea'">
        </div>
        <div class="balance">
            <span>余额：</span>
            <span>{{ gold }}</span>
        </div>
        <div class="rotateArea">
            <img :src="primaryImg" alt="" id="primaryBox">
            <div class="btns">
                <img :src="oneImg" alt="" class="playBtn playBtn1" @click="batchPlay('one')">
                <img :src="tenImg" alt="" class="playBtn playBtn10" @click="batchPlay('ten')">
                <img :src="hundredImg" alt="" class="playBtn playBtn5" @click="batchPlay('hundred')"
                    v-if="isModal === 'secretarea'">
                <img :src="fiftyImg" alt="" class="playBtn playBtn5" @click="batchPlay('fifty')"
                    v-if="isModal === 'fairyland'">
                <!-- <div @click="batchPlay('one')" class="playBtn">
                    <span>寻宝一次：</span>
                    <span>{{ game.price }}</span>
                </div>
                <div @click="batchPlay('ten')" class="playBtn">
                    <span>寻宝十次：</span>
                    <span>{{ game.ten_price }}</span>
                </div>
                <div @click="batchPlay('hundred')" class="playBtn">
                    <span>寻宝五次：</span>
                    <span>{{ game.hundred_price }}</span>
                </div> -->
            </div>
        </div>
        <audio id="audio" ref="audio">
            <!-- <source src="https://storage.wnwdkj.cn/turnbg.wav" type="audio/wav"> -->
            <source :src="music" type="audio/mpeg">
        </audio>
        <rule :content="game.remark" v-show="showRule" @close="showRule = false" />
        <prize v-if="showPrize" :prize="prize" @close="reset" />
    </div>
</template>

<script>
import prize from "@/components/prize";
import rule from "@/components/rule";
import '@/assets/kunpeng/css/index.css'
import '@/assets/jquery.rotate'
import { Toast } from "vant";
import primaryBox_sa from '../../assets/kunpeng/image/primary_sa.gif'
import rotate_sa from '../../assets/kunpeng/image/rotate_sa.gif'
import rule_sa from '../../assets/kunpeng/image/rule_sa.png'
import close_sa from '../../assets/kunpeng/image/close_sa.png'
import area_sa from '../../assets/kunpeng/image/area_sa.png'
import go_sa from '../../assets/kunpeng/image/go_sa.png'
import kun_sa from '../../assets/kunpeng/image/kun_sa.png'
import one_sa from '../../assets/kunpeng/image/one_sa.png'
import ten_sa from '../../assets/kunpeng/image/ten_sa.png'
import hundred_sa from '../../assets/kunpeng/image/hundred_sa.png'
import bg_sa from '../../assets/kunpeng/image/bg_sa.png'

import high_fl from '../../assets/kunpeng/image/xianjing/high.gif'
import rotate_fl from '../../assets/kunpeng/image/xianjing/rotate_fl.gif'
import area_fl from '../../assets/kunpeng/image/xianjing/area_fl.png'
import bg_fl from '../../assets/kunpeng/image/xianjing/bg_fl.png'
import close_fl from '../../assets/kunpeng/image/xianjing/close_fl.png'
import fifty_fl from '../../assets/kunpeng/image/xianjing/fifty_fl.png'
import go_fl from '../../assets/kunpeng/image/xianjing/go_fl.png'
import one_fl from '../../assets/kunpeng/image/xianjing/one_fl.png'
import rule_fl from '../../assets/kunpeng/image/xianjing/rule_fl.png'
import ten_fl from '../../assets/kunpeng/image/xianjing/ten_fl.png'



import one_fl_at from '../../assets/kunpeng/image/xianjing/one_fl_at.png'
import fifty_fl_at from '../../assets/kunpeng/image/xianjing/fifty_fl_at.png'
import ten_fl_at from '../../assets/kunpeng/image/xianjing/ten_fl_at.png'



import one_sa_at from '../../assets/kunpeng/image/one_sa_at.png'
import hundred_sa_at from '../../assets/kunpeng/image/hundred_sa_at.png'
import ten_sa_at from '../../assets/kunpeng/image/ten_sa_at.png'


import rotatem from '../../assets/kunpeng/music/rotatem.mp3';
import giftm from '../../assets/kunpeng/music/giftm.mp3';


export default {
    name: "index",
    components: {
        prize,
        rule
    },
    data() {
        return {
            showRule: false,
            showPrize: false,
            prize: null,
            game: null,
            gold: 0,
            code: 'secretarea',  // 秘境  secretarea  仙境 fairyland
            inDraw: false,
            ruleImg: rule_sa,
            closeImg: close_sa,
            areaImg: area_sa,
            primaryImg: primaryBox_sa,
            rotateImg: rotate_sa,
            oneImg: one_sa,
            tenImg: ten_sa,
            hundredImg: hundred_sa,
            fiftyImg: fifty_fl,
            goImg: go_sa,
            kunImg: kun_sa,
            isModal: 'secretarea',
            limit: '',
            rotatem,
            giftm,
            music: rotatem,
        }
    },
    mounted() {
        this.getInfo()
    },
    watch: {
        'globalData.XPLATFORM': {
            handler(newVal) {
                newVal && this.getInfo()
            },
        }
    },
    methods: {
        getInfo() {
			
			// console.log(this.globalData.XPLATFORM)
            //if (this.globalData.XPLATFORM) {
                this.$http.get('lottery-game/info', {
                    params: { code: this.isModal },
                    headers: {
                        'X-PLATFORM': this.globalData.XPLATFORM
                    }
                }).then((res) => {
                    this.game = res.data;
                    this.gold = res.data['gold']
                })
          //  }
        },
        reset() {
            this.music = this.rotatem;
            this.$refs.audio.pause();
            this.inDraw = false;
            this.showPrize = false;
            this.changeBtnImg()
            this.getInfo();
        },
        changeBtnImg() {
            if (this.isModal === 'secretarea') {
                if (this.limit == 'one') {
                    this.oneImg = one_sa_at;
                    this.hundredImg = hundred_sa;
                    this.tenImg = ten_sa;
                };
                if (this.limit == 'hundred') {
                    this.oneImg = one_sa;
                    this.hundredImg = hundred_sa_at;
                    this.tenImg = ten_sa;
                }
                if (this.limit == 'ten') {
                    this.oneImg = one_sa;
                    this.hundredImg = hundred_sa;
                    this.tenImg = ten_sa_at;
                }

            } else {
                if (this.limit == 'one') {
                    this.oneImg = one_fl_at;
                    this.fiftyImg = fifty_fl;
                    this.tenImg = ten_fl;
                };
                if (this.limit == 'fifty') {
                    this.oneImg = one_fl;
                    this.fiftyImg = fifty_fl_at;
                    this.tenImg = ten_fl;
                }
                if (this.limit == 'ten') {
                    this.oneImg = one_fl;
                    this.fiftyImg = fifty_fl;
                    this.tenImg = ten_fl_at;
                }
            }
        },
        getData(type) {
            if (!this.globalData.XPLATFORM) {
                return
            }
            if (this.inDraw) {
                return
            }
            this.inDraw = true;
            var that = this;
            that.$http.post('lottery-game/draw', { type, code: that.isModal, room_id: that.globalData.roomId }, {
                headers: { 'X-PLATFORM': that.globalData.XPLATFORM }
            }).then((res) => {
                that.prize = res;
                setTimeout(() => {
                    that.music = that.giftm;
                    that.$refs.audio.load();
                    that.$refs.audio.play()
                    $('#primaryBox').attr('src', that.primaryImg)
                    that.showPrize = true
                }, 2)
            }).catch(() => {
                that.reset()
            });
        },
        batchPlay(type) {
            this.$refs.audio.load();
            this.$refs.audio.play()
            this.limit = type;
            let num = 'price'
            if (type == 'hundred') {
                num = 'hundred_price'
            } else if (type == 'ten') {
                num = 'ten_price'
            } else if (type == 'fifty') {
                num = 'fifty_price'
            };
            if (parseInt(this.gold) < parseInt(this.game[num])) {
                return Toast.fail({ message: '余额不足', position: 'bottom' })
            };
            $('#primaryBox').attr('src', this.rotateImg)
            this.getData(type)
        },

        changeModal() {
            if (this.isModal === 'secretarea') {
                $('.wrap').css({ "background-image": `url(${bg_fl})` });
                this.ruleImg = rule_fl;
                this.closeImg = close_fl;
                this.areaImg = area_fl;
                this.primaryImg = high_fl;
                this.rotateImg = rotate_fl;
                this.oneImg = one_fl;
                this.fiftyImg = fifty_fl;
                this.tenImg = ten_fl;
                this.goImg = go_fl;
                this.isModal = 'fairyland';
            } else {
                $('.wrap').css({ "background-image": `url(${bg_sa})` })
                this.ruleImg = rule_sa;
                this.closeImg = close_sa;
                this.areaImg = area_sa;
                this.primaryImg = primaryBox_sa;
                this.rotateImg = rotate_sa;
                this.oneImg = one_sa;
                this.hundredImg = hundred_sa;
                this.tenImg = ten_sa;
                this.goImg = go_sa;
                this.isModal = 'secretarea'
            }
            this.getInfo()
        }
    }
}
</script>

<style scoped></style>
